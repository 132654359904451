<template>
  <div class="user-product">
    <x-table
      :no-data-text="CA('userProduct_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadEnter="loadEnter"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="产品名称" prop="name">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="产品分类" prop="categoryId">
          <Select v-model="form.categoryId">
            <Option v-for="item in category" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="系统产品" prop="productId">
          <Select v-model="form.productId">
            <Option
              v-for="item in sysProduct"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="modalClassify.show" :title="modalClassify.title" fullscreen>
      <userProductCategory @change="getCategory"></userProductCategory>
    </Modal>
  </div>
</template>

<script>
import userProductCategory from "../userProductCategory/index.vue";
export default {
  components: { userProductCategory },
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "产品名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "分类名称",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "系统产品",
            minWidth: 100,
            key: "productName",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("userProduct_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("userProduct_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      sysProduct: [],
      category: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      modalClassify: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        categoryId: "",
        productId: "",
      },
      rules: {
        name: [{ required: true, message: "请填写产品名称" }],
        categoryId: [{ required: true, message: "请选择产品分类" }],
        productId: [{ required: true, message: "请选择系统产品" }],
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增作物信息",
            ca: "userProduct_add",
          },
          loadEnter: {
            loadName: "品类管理",
            ca: "userProduct_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "产品名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "系统产品",
              component: "select",
              field: "productId",
              defaultValue: "",
              data: this.sysProduct,
              parameterField: "id",
              showField: "name",
              clearable: true,
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增产品",
        submitLoading: false,
      };
    },
    loadEnter() {
      this.modalClassify = {
        show: true,
        title: "品类管理",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    getList() {
      if (!this.CA("userProduct_check")) return;
      this.table.loading = true;
      this.$post(this.$api.USER_PRODUCT.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        type: 1,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getSystemProduct() {
      this.$post(this.$api.PRODUCT_INFO.LIST).then((res) => {
        this.sysProduct = res.list;
      });
    },
    getCategory() {
      this.$post(this.$api.USER_PRODUCT_CATEGORY.LIST).then((res) => {
        this.category = res.list;
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑产品",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.USER_PRODUCT.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        categoryId: "",
        productId: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.modal.submitLoading = true;
        this.$post(
          this.form.id
            ? this.$api.USER_PRODUCT.EDIT
            : this.$api.USER_PRODUCT.ADD,
          {
            ...this.form,
          }
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getSystemProduct();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.user-product {
  width: 100%;
  height: 100%;
}
</style>
